<template>
    <div class="container-geral">
        <div class="container">
            <form action="" @submit.prevent="validaUsuario" id="formLogin">
                <div class="container-usuario">
                    <h1 class="label-container">Login Gestor</h1>
                    <div class="label-usuario">
                        <span class="usuario"> Usuário * </span>
                    </div>
                    <div class="input-usuario">
                        <input :class="{ 'input-invalid': isInvalid }" type="text" v-model="usuario" required />
                    </div>
                </div>
                <div class="container-senha">
                    <div class="label-senha">
                        <span class="senha"> Senha * </span>
                    </div>
                    <div class="input-senha">
                        <input :class="{ 'input-invalid': isInvalid }" type="password" v-model="senha" required />
                    </div>
                </div>
                <small v-if="isInvalid" class="usuario-invalido">Usuário ou senha inválidos</small>
                <h3 class="version">Versão 1.0.3</h3>
            </form>
        </div>
        <button class="btn-update" v-if="isRefresh" @click="update">Atualizar App</button>
        <transition name="fade">
            <div v-if="showSelect" class="popUp">
                <div class="tela-mensagem">
                    <div class="cancel-msg">
                        Escolha a loja:
                        <div class="close-cancel" @click="showSelect = false">
                            <img src="../assets/img/close-black.svg" alt="fechar a modal" />
                        </div>
                    </div>
                    <div class="container-select">
                        <select class="selectLoja" name="selectLoja" id="selectLoja" @change="alteraLoja($event)">
                            <option v-for="loja in lojas" :key="loja.loja" :value="loja.loja">
                                {{ loja.loja }}
                            </option>
                        </select>
                    </div>
                    <div class="cancel-btn">
                        <button @click="showSelect = false" type="button">Cancelar</button>
                        <button @click="logar()" type="button">Entrar</button>
                    </div>
                </div>
            </div>
        </transition>
        <transition name="fade">
            <div class="popUp" v-if="mensagem.isSend">
                <div class="tela-mensagem">
                    <div class="error" v-if="mensagem.error">
                        <img src="../assets/img/alert-white.svg" alt="imagem de check" />
                    </div>
                    <div class="sucess" v-else>
                        <img src="../assets/img/check-white.svg" alt="imagem de check" />
                    </div>
                    <div class="sucess-msg">
                        {{ mensagem.body }}
                    </div>
                </div>
            </div>
        </transition>
        <msg-error v-if="showAlert" :mensagemError="msgE"></msg-error>
    </div>
</template>

<script>
import axios from 'axios';
import MsgError from '../components/MsgError.vue';
import HTTP from '../http/http.js';
// const crypto = require('crypto'); // Primeiro importar

export default {
    data() {
        return {
            usuario: 'comercial',
            senha: 'comercial',
            isInvalid: false,
            showAlert: false,
            msgE: 'Login inválido! Verifique com o suporte.',
            showSelect: false,
            lojas: [],
            lojaSelecionada: '1001',
            mensagem: {},
            registration: null,
            isRefresh: false,
            refreshing: false,
        };
    },

    components: {
        MsgError,
    },

    created() {
        document.addEventListener('serviceWorkerUpdateEvent', this.appUpdateUI, { once: true });
    },

    methods: {
        validaUsuario() {
            if (this.usuario == '' || this.senha == '') {
                this.isInvalid = true;
            }

            let senha = this.senha.toLowerCase();
            // senha = crypto.createHash('sha1').update(senha).digest('hex');

            let data = {
                nome: this.usuario,
                senha: senha,
                novaAutenticacao: true,
            };

            axios
                .post(`${process.env.VUE_APP_BASE_HTTP}/login-admin`, data)
                .then((response) => {
                    let usuario = response.data;
                    localStorage.setItem('token', usuario.token);
                    sessionStorage.setItem('usuario', usuario.nome);
                    sessionStorage.setItem('tipoUsuario', usuario.tipo_usuario);
                    sessionStorage.setItem('categoriaUsuario', usuario.categoria);

                    if (usuario.admin == 1) {
                        HTTP.get(`/lojas`)
                            .then((response) => {
                                this.showSelect = true;
                                this.lojas = response.data;
                            })
                            .catch(() => {
                                console.error('erro');
                            });
                    } else {
                        sessionStorage.setItem('loja', usuario.loja);

                        if (this.mensagem.isSend != true) {
                            setTimeout(() => {
                                this.mensagem = {
                                    isSend: false,
                                    body: ``,
                                    error: false,
                                };
                                this.$router.push({ name: 'carregamento' });
                            }, 500);
                        }
                    }
                })
                .catch(() => {
                    this.isInvalid = true;
                    this.showAlert = true;
                    this.fechaAlert();
                });
        },

        logar() {
            sessionStorage.setItem('loja', this.lojaSelecionada);

            this.$router.push({ name: 'carregamento' });
        },

        alteraLoja(event) {
            this.lojaSelecionada = event.target.value;
        },

        fechaAlert() {
            setTimeout(() => {
                this.showAlert = false;
            }, 1800);
        },

        update() {
            this.isRefresh = false;
            if (this.registration || this.registration.waiting) {
                this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            }
            window.location.reload(true);
        },

        appUpdateUI: function (e) {
            this.registration = e.detail;
            this.isRefresh = true;
        },
    },
};
</script>

<style scoped>
.btn-update {
    width: 50%;
    align-self: center;
    justify-self: center;
    background-color: #56bb8f;
    padding: 10px 5px;
    color: #fff;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    transition: all 1s;
}

.btn-update:hover {
    position: relative;
    top: 3px;
    left: 3px;
}

.container-geral {
    display: grid;
}

.container {
    padding: 20px;
    box-shadow: 0 0 5px #cdcdcd;
    border-radius: 10px;
    background-color: #fefefe;
    margin: 100px 10%;
}

.container-select {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.selectLoja {
    border: none;
    border-radius: 5px;
    box-shadow: 0 0 2px #525252;
    outline-color: #ef6969;
    background-color: #fbfbfb;
    width: 30%;
    padding: 5px;
}

.label-container {
    margin-bottom: 10px;
    color: var(--verde);
    font-weight: 600;
}

form div {
    padding: 5px 0;
}

form div div span {
    font-size: 0.8rem;
    color: #5f5e5e;
}

form div div input {
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
}

form div div input:focus {
    outline-color: var(--verde);
}

.usuario-invalido {
    font-size: 0.7rem;
    color: red;
}

.input-invalid {
    border-color: red;
}

.btn-reset {
    border: 1px solid #cdcdcd;
    background-color: #f4f4f4;
    padding: 10px;
    border-radius: 10px;
    color: var(--verde);
    font-weight: 500;
    margin: 0 70px;
    cursor: pointer;
}

.btn-reset:hover {
    background-color: var(--verde);
    color: #fff;
}

.popUp {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
}

.tela-mensagem {
    position: absolute;
    left: 15%;
    top: 20%;
    right: 15%;
    margin: 18px;
    background-color: #fff;
    border-radius: 5px;
    padding-bottom: 10px;
}

.tela-mensagem h3 {
    padding: 5px 15px;
    font-size: 15px;
}

.cancel-msg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    font-size: 23px;
    font-weight: bold;
}

.close-cancel {
    display: flex;
    background-color: #d3d3d3;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s;
}

.close-cancel:hover {
    border-radius: 50%;
    transition: all 0.2s;
    background-color: #f4f4f4;
    border: 1px solid #d3d3d3;
}

.cancel-btn {
    margin-top: 8%;
    padding: 10px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.cancel-btn button {
    padding: 7px 15px;
    font-weight: bold;
    background-color: #f4f4f4;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    cursor: pointer;
}

.cancel-btn button:hover {
    position: relative;
    top: 5px;
    background-color: var(--verde);
    color: #fff;
}
.error {
    padding: 10px 20px;
    text-align: center;
    background-color: #ef6969;
    color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
}

.error-icon {
    font-size: 80px;
}

.sucess {
    background-color: #64b058;
    padding: 10px 20px;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.sucess img {
    width: 100px;
}

.error img {
    width: 100px;
}

.sucess-msg {
    padding: 20px;
    height: 50%;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    text-align: center;
}

.version {
    font-size: 12px;
    text-align: center;
    margin-top: 15px;
}

@media (max-width: 327px) {
    .cancel-msg {
        font-size: 16px !important;
    }

    .tela-mensagem h3 {
        text-align: center;
    }

    .cancel-btn button {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    .tela-mensagem {
        left: 5%;
        right: 5%;
    }

    .cancel-msg {
        font-size: 20px;
    }

    .cancel-btn button {
        font-size: 16px;
    }
}
</style>
